<template>
  <div>
    <section class="product-page-header_wrap" v-if="this.$route.path !== '/style-trend'">
      <h1>
        <a href="/style">
          <img
            src="https://dosi-in.com/images/assets/icons/style.png"
            alt="Dosi-in"
          />
        </a>
      </h1>
      <h2 class="subtitle">{{ $t("style.welcome") }}</h2>
    </section>
    <TopTrending />
    <div class="dosiin_style-pc">
      <Hashtag />
      <PostsLoader
        :params="{
          sort_by: 'random',
          items_per_page: 15,
          timestamp: new Date().getTime(),
        }"
      />
    </div>
  </div>
</template>

<script>
import Hashtag from "@/components/hashtag/HashTag.vue";
import { defineAsyncComponent } from "vue";
import PostsLoader from "@/components/post/PostLoader.vue";
export default {
  name: "styles",
  setup() {},
  components: {
    TopTrending: defineAsyncComponent(() => import("@/components/post/TopTrending.vue")),
    PostsLoader,
    Hashtag,
  },
};
</script>
