<template>
  <div
    class="box-wrapper-pc box-wrapper-pc_bg-unset box-wrapper-pc_width-unset dosiin_padding-header-top dosiin_pd-top-24"
  >
    <TopTrending />
    <div class="box-grid-pc">
      <div class="box-grid-pc_left">
        <PostsLoader :key="params.sort_by + params.sort_order" :params="params" />
      </div>
      <div class="box-grid-pc_right" :class="[$style.stick_right]">
        <GroupTags />
        <div class="dosiin_mt-16"></div>
        <Hashtag />
        <Policy />
      </div>
    </div>
  </div>
</template>

<script>
import Hashtag from "@/components/hashtag/HashTag.vue";
import Policy from "@/components/common/Policy";
import { defineAsyncComponent, reactive } from "vue";
import GroupTags from "@/components/hashtag/GroupTags.vue";

export default {
  name: "styles",
  components: {
    Hashtag,
    GroupTags,
    Policy,
    TopTrending: defineAsyncComponent(() => import("@/components/post/TopTrending.vue")),
    PostsLoader: defineAsyncComponent(() => import("@/components/post/PostLoader.vue")),
  },
  setup() {
    const params = reactive({
      sort_by: "random",
      sort_order: "desc",
      items_per_page: 15,
      timestamp: new Date().getTime(),
    });

    return {
      params,
    };
  },
};
</script>

<style module>
.stick_right {
  position: sticky;
  top: 104px;
  height: 100%;
}
</style>
