<template>
    <div class="footer_post-txt">
        <ul>
            <li> <a href="/">Về Dosiin</a></li>
            <li> <a href="/">Chính sách</a></li>
            <li> <a href="/">Điều khoản</a></li>
            <li> <a href="/">Dịch vụ</a></li>
        </ul>
        <p>Copyright 2015-2020 © CÔNG TY CỔ PHẦN DOSI GROUP</p>
    </div>
</template>