<template>
  <MobileVnfd v-if="layout == 4" />
  <DesktopVnfd v-else />
</template>

<script>
import MobileVnfd from "@/pages/mb/Vnfd.vue";
import DesktopVnfd from "@/pages/pc/Vnfd.vue";

export default {
  name: "styles",
  components: {
    MobileVnfd,
    DesktopVnfd,
  },
};
</script>
